import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(
  ({breakpoints, spacing, palette}) => ({
    root: {
      position: 'relative',
      height: 684,
      [breakpoints.up(552)]: {
        height: 652,
      },
      [breakpoints.up(900)]: {
        height: 728,
      },
      [breakpoints.up(1440)]: {
        height: 820,
      },
      [breakpoints.up(2000)]: {
        height: 900,
      },
    },
    nextImageContainer: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      background: 'white', //bottom color of picture
    },

    nextImageMobile: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      filter: 'saturate(1.2) brightness(1.03) contrast(1.03)',
      objectPosition: 'bottom',
      display: 'block',
      [breakpoints.up(552)]: {
        display: 'none',
      },
    },
    nextImageTablet: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      filter: 'saturate(1.2) brightness(1.03) contrast(1.03)',
      objectPosition: 'center',
      display: 'none',
      [breakpoints.up(552)]: {
        display: 'block',
      },
      [breakpoints.up(1128)]: {
        display: 'none',
      },
    },
    nextImageAllSizes: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      filter: 'saturate(1.2) brightness(1.03) contrast(1.03)',
      display: 'block',
    },
    nextImageDesktop: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      filter: 'saturate(1.2) brightness(1.03) contrast(1.03)',
      display: 'none',
      objectPosition: 'center',
      [breakpoints.up(1128)]: {
        display: 'block',
        //objectPosition: '50% 60%',
      },
    },
    container: {
      height: '100%',
      [breakpoints.down(1360)]: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
      height: '100%',
      minHeight: '100%',
      position: 'relative',
      marginTop: '240px',

      [breakpoints.up(552)]: {
        marginTop: '200px',
      },

      [breakpoints.up(900)]: {
        marginTop: '180px',
        display: 'block',
        minHeight: 'auto',
      },
    },
    star: {
      [breakpoints.down(900)]: {
        display: 'none',
      },
    },
    handleSearchMobile: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: spacing(1.25, 2.5),
      width: '100%',
      height: 44,
      marginTop: spacing(1),
      //  background: palette.primary.main,
      borderRadius: spacing(3),
    },
    desktopSearch: {
      display: 'none',
      [breakpoints.up(900)]: {
        display: 'block',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '150px',
      },
      [breakpoints.up(1128)]: {
        width: '90%',
      },
      [breakpoints.up(1360)]: {
        width: '80%',
      },
    },
    mobileSearch: {
      width: '100%',
      marginTop: '100px',
      [breakpoints.up(375)]: {
        marginTop: '180px',
      },
      [breakpoints.up(552)]: {
        marginTop: '135px',
      },
      [breakpoints.up(900)]: {
        display: 'none',
      },
    },
  }),
  {index: 2},
)

interface Props {
  inTopBar?: boolean
  isSmallSize: boolean
}

export const useSearchStyles = makeStyles(
  ({palette, breakpoints, spacing, shadows}) => ({
    flexWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& input': {
        width: '100%',
        border: 'none',
        fontSize: 14,
        '&::placeholder': {
          color: palette.grey[700],
          fontWeight: 500,
          opacity: 1,
        },
        '&:focus': {
          border: 'none',
          outline: 'none',
        },
      },
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
        marginBottom: 0,
        '& .MuiInput-underline': {
          '&:before': {
            border: 'none',
          },
        },
      },
      [breakpoints.down(900)]: {
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: spacing(1),
      },
    },
    inputAutoComplete: ({inTopBar}: Props) => ({
      width: '33%',
      height: 'auto',
      display: 'flex',
      padding: inTopBar ? spacing(0, 0, 0, 1) : 0,
      maxWidth: inTopBar ? 116 : 'none',
      minWidth: inTopBar ? 90 : 'none',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'relative',
      flexDirection: 'row',
      '& div': {
        width: '100%',
      },
      '&::placeholder': {
        fontFamily: 'Inter, Arial, sans-serif',
        fontWeight: 500,
        fontSize: 14,
        color: palette.grey[600],
      },
      transition: 'all',

      '& .MuiInputBase-root': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        marginTop: '-16px',
      },
      '& input': {
        background: 'rgba(0,0,0,0)',
        padding: 0,
      },
      '&:hover > div': {
        backgroundColor: '#F6F6F7',
      },
    }),
    locationIcon: {
      width: spacing(15 / 8),
      marginRight: spacing(2),
      [breakpoints.down(900)]: {
        margin: spacing(14 / 8, 15 / 8, 14 / 8, 0),
      },
    },
    root: {
      alignItems: 'center',
      background: palette.background.paper,
      borderRadius: spacing(1.5),
      display: 'flex',
      boxShadow: '0px 32px 96px -36px rgba(26, 34, 50, 0.08)',
      height: spacing(12),
      width: '100%',
      padding: spacing(0, 3, 0, 39 / 8),
      [breakpoints.down(870)]: {
        padding: spacing(1.5),
      },
      [breakpoints.down(900)]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        minHeight: 256,
      },
      paddingLeft: spacing(5),
      paddingRight: spacing(3),
    },
    searchPrimaryBtn: {
      marginLeft: 'auto',
      padding: '12px 50px',
      [breakpoints.down(870)]: {
        padding: spacing(1.5, 2.5),
      },
      [breakpoints.down(900)]: {
        width: '100%',
        marginTop: spacing(2),
      },
    },
    button: {
      padding: '12px 61.5px',
      display: 'block',
      width: '100%',
      color: 'white',
      [breakpoints.down(552)]: {
        width: '100%',
      },
      '& span': {
        color: 'white !important',
      },
    },
    paper: {
      // color: "#5A6987",
      padding: spacing(5 / 8, 1),
      textAlign: 'center',
      margin: spacing(0, 0.5),
      background: 'transparent',
      boxShadow: 'none',
      color: palette.text.secondary,
    },

    searchButtonContainer: ({inTopBar}: Props) => ({
      width: inTopBar ? '120px !important' : '100% !important',
      height: 'auto',
      margin: spacing(0, 1),
      display: 'flex',
      border: inTopBar ? `1px solid ${palette.grey[200]}` : 'none',
      padding: inTopBar ? '8px 12px' : '8px 16px',
      flexGrow: 0,
      background: palette.common.white,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      textAlign: 'left',
      borderRadius: 0,

      [breakpoints.down(1360)]: {
        padding: inTopBar ? spacing(1, 1.5) : spacing(0.25, 0.75),
      },
    }),
    searchDatesButtonContainer: ({inTopBar}: Props) => ({
      width: inTopBar ? '150px !important' : '100% !important',
    }),
    searchMenuContainer: {
      // maxWidth: '1128px',
      height: '80px',
      display: 'flex',
      padding: spacing(1, 2),
      background: palette.common.white,
      boxShadow: shadows[2],
      alignItems: 'center',
      borderRadius: '6px',
      flexDirection: 'row',
      width: '100%',
    },
    searchButtonIcon: {
      width: 14,
      height: 14,
      marginRight: 21,
      color: palette.grey[500],
    },
    searchButtonStyle: ({inTopBar}: Props) => ({
      minWidth: !inTopBar ? '48px !important' : '64px',
      width: !inTopBar ? '48px !important' : 'auto',
      borderRadius: !inTopBar ? '3px' : 0,
      height: !inTopBar ? '48px' : 'auto',
      padding: 0,
      transition: 'width 150ms',

      ...(!inTopBar && {minWidth: '48px !important'}),
      ...(inTopBar && {background: 'transparent', marginLeft: '-15px'}),
      // background: inTopBar ? "transparent" : "#FFF",
      '&:hover': {
        ...(inTopBar && {background: 'transparent'}),
      },
    }),
    searchLongButtonStyle: ({inTopBar}: Props) => ({
      width: '190px!important',
    }),
    searchMenuButtonContainer: ({inTopBar}: Props) => ({
      ...(inTopBar && {maxWidth: 100}),
      width: '33%',
    }),
    amenitiesMenu: {
      '& .MuiPopover-paper': {
        maxWidth: 1130,
        margin: '15px auto !important',
        left: '0 !important',
        right: '0 !important',
        borderRadius: '16px',
        boxShadow: '0 0 0 1px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 15%)',
        [breakpoints.down(1128)]: {
          margin: '10px !important',
          left: '0px !important',
        },
      },
    },
    headerSearchScroll: {
      position: 'relative',
      height: 'auto',
      padding: spacing(7 / 8, 0),
      border: `1px solid ${palette.grey[200]}`,
      margin: spacing(0, 0, 0, 15 / 8),
      boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 4px 12px',
      '& .searchMenuButtonContainer': {
        flex: '0 0 116px',
        maxWidth: 116,
        width: '100%',
        '& .searchButtonContainer': {
          height: 'auto',
        },
      },
    },

    inputGrid: ({inTopBar}: Props) => ({
      ...(inTopBar && {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }),
    }),
    truncate: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flexDirection: 'row',
      color: ` ${palette.grey[600]} !important`,
      lineHeight: 'normal',
      padding: spacing(0.5, 0),
    },
    smallFont: {
      fontSize: '12px !important',
    },
    guestLabel: {
      padding: '4px 0px',
    },
    guestContainer: {
      marginLeft: '-1px !important',
    },
  }),
  {index: 2},
)

export const useSearchTopStyles = makeStyles(
  ({palette, breakpoints, spacing}) => ({
    flexWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& input': {
        width: '100%',
        border: 'none',
        fontSize: 14,
        '&::placeholder': {
          color: '#252E41',
          fontWeight: 500,
          opacity: 1,
        },
        '&:focus': {
          border: 'none',
          outline: 'none',
        },
      },
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
        marginBottom: 0,
        '& .MuiInput-underline': {
          '&:before': {
            border: 'none',
          },
        },
      },
      [breakpoints.down(900)]: {
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '8px',
      },
    },
    inputAutoCompleteInTop: ({inTopBar}: Props) => ({
      width: '100%',
      //   height: 'auto',
      display: 'flex',
      border: `1px solid ${palette.grey[200]}`,
      padding: inTopBar ? '0 0 0 8px' : 0,
      maxWidth: inTopBar ? '116px' : '200px',
      minWidth: inTopBar ? '90px' : undefined,
      height: '43px',
      alignItems: 'center',

      '& input': {
        background: 'rgba(0,0,0,0)',
        // padding: 0,
        height: '43px',
      },

      [breakpoints.down(1360)]: {
        width: '140px',
      },

      '& div': {
        width: '100%',
      },
      '&::placeholder': {
        fontFamily: 'Inter, Arial, sans-serif',
        fontWeight: 500,
        fontSize: 14,
        color: '#364259',
      },
    }),
    locationIcon: {
      marginRight: spacing(2),
      [breakpoints.down(900)]: {
        margin: spacing(14 / 8, 15 / 8, 14 / 8, 0),
      },
    },
    root: {
      alignItems: 'center',
      background: palette.background.paper,
      borderRadius: spacing(1.5),
      display: 'flex',
      boxShadow: '0px 32px 96px -36px rgba(26, 34, 50, 0.08)',
      height: spacing(12),
      width: '100%',
      padding: spacing(0, 3, 0, 39 / 8),
      [breakpoints.down(870)]: {
        padding: spacing(1.5),
      },
      [breakpoints.down(900)]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        minHeight: 256,
      },
      paddingLeft: spacing(5),
      paddingRight: spacing(3),
    },
    searchPrimaryBtnInTop: {
      padding: '12px 50px',
      [breakpoints.down(870)]: {
        padding: '12px 20px',
      },
      [breakpoints.down(900)]: {
        width: '100%',
        marginTop: '16px',
      },
    },
    button: {
      padding: '12px 61.5px',
      display: 'block',
      width: '100%',
      color: 'white',
      [breakpoints.down(552)]: {
        width: '100%',
      },

      '& span': {
        color: 'white !important',
      },
    },
    paper: {
      // color: "#5A6987",
      padding: '5px 8px',
      textAlign: 'center',
      margin: '0 4px',
      background: 'transparent',
      boxShadow: 'none',
      color: palette.text.secondary,
    },
    searchButtonContainer: ({inTopBar}: Props) => ({
      width: '100% !important',
      height: 'auto',
      margin: '0px 0px',
      display: 'flex',
      padding: inTopBar ? '8px 12px' : '8px 12px',
      flexGrow: 0,
      background: '#FFFFFF',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      textAlign: 'left',
      [breakpoints.down(1360)]: {
        padding: inTopBar ? '0px 0px' : '2px 6px',
      },
    }),

    searchMenuContainer: {
      maxWidth: '1128px',
      height: '80px',
      display: 'flex',
      padding: '8px',
      background: '#FFFFFF',
      boxShadow: '0px 32px 96px -36px rgba(26, 34, 50, 0.08)',
      alignItems: 'center',
      borderRadius: '6px',
      flexDirection: 'row',
      width: '100%',
    },
    searchButtonIcon: {
      width: '14px',
      height: '14px',
      marginRight: '21px',
      color: '#5A6987',
    },
    searchButtonStyle: ({inTopBar}: Props) => ({
      minWidth: !inTopBar ? '40px !important' : '64px',
      width: !inTopBar ? '40px !important' : 'auto',
      borderRadius: !inTopBar ? '3px' : 0,
      height: !inTopBar ? '40px' : 'auto',
      padding: 0,

      ...(!inTopBar && {minWidth: '40px !important', marginRight: 15}),
      ...(inTopBar && {background: 'transparent', marginLeft: -15}),
      '&:hover': {
        ...(inTopBar && {background: 'transparent'}),
      },
    }),
    searchMenuButtonContainer: ({inTopBar}: Props) => ({
      ...(inTopBar && {maxWidth: 100}),
    }),
    amenitiesMenu: {
      '& .MuiPopover-paper': {
        maxWidth: '1130px',
        margin: '15px auto !important',
        left: '0 !important',
        right: '0 !important',
        borderRadius: '16px',
        boxShadow: '0 0 0 1px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 15%)',
        [breakpoints.down(1128)]: {
          margin: '10px !important',
          left: '0px !important',
        },
      },
    },
    headerSearchScroll: {
      position: 'relative',
      // maxWidth: "500px",
      height: 'auto',
      padding: '7px 0',
      // border: `1px solid ${palette.grey[200]}`,
      margin: '0 0 0 15px',
      // flex: '0 0 550px',
      '& .searchMenuButtonContainer': {
        flex: '0 0 116px',
        maxWidth: '116px',
        width: '100%',
        '& .searchButtonContainer': {
          height: 'auto',
        },
      },
    },
    truncate: {
      lineHeight: 'normal',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flexDirection: 'row',
      color: ` ${palette.grey[600]} !important`,
    },
    smallFont: {
      fontSize: '12px !important',
    },
    guestLabel: {
      padding: spacing(0.5, 0),
    },
    inputGrid: ({inTopBar}: Props) => ({
      ...(inTopBar && {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }),
    }),
  }),
  {index: 2},
)
